import React, { memo } from "react";
import { Row } from "antd";
import CRUDComponent from "../../../../component/common/CRUD-Component";
import { useNavigate } from "react-router-dom";
import CONSTANTS from "../../../../util/constant/CONSTANTS";
import { convertUTCToLocal } from "../../../../util/functions";

const ReferredUser = ({ referenceBy }) => {
  return (
    <>
      <>
        <Row>
          <CRUDComponent
            GET={{
              API: CONSTANTS.API.user.getAll,
              extraQuery: { referenceCode: referenceBy },
              DataModifier: (res, API, Setrefresh) => {
                return res?.map((data) => {
                  return {
                    ...data,
                    no: data?.no,
                    createdDate: convertUTCToLocal(data?.createdAt),
                  };
                });
              },
              column: CONSTANTS.TABLE.REFERRED_HISTORY,
            }}
            isSearch
          />
        </Row>
      </>
    </>
  );
};

export default memo(ReferredUser);
